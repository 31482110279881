<template>
	<v-dialog v-model="isOpenMyPageNoticeDialog" persistent max-width="700">
		<v-card>
			<v-card-title :class="{ 'px-3': isSmAndDown }">
				<span class="text-base">{{ notice.title }}</span>
				<v-spacer />
				<v-btn icon @click="closeDialog">
					<v-icon size="22">mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-divider class="mb-3" />
			<v-card-text :class="{ 'px-3': isSmAndDown }">
				<span v-html="notice.content"></span>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { ref, watch, computed } from '@vue/composition-api'

import { getVuetify } from '@core/utils'
import { warningSwal } from '@/plugins/swalMixin'

import NoticeService from '@/services/NoticeService'

export default {
	props: {
		selectedNotice: {
			type: Object,
		},
		isOpenMyPageNoticeDialog: {
			type: Boolean,
			required: true,
		},
	},
	setup(props, { emit }) {
		const notice = ref({})
		const $vuetify = getVuetify()

		const getNoticeDetail = async noticeId => {
			try {
				notice.value = await NoticeService.getNoticeDetail(noticeId)
			} catch (e) {
				warningSwal('공지사항을 가져오는데 문제가 발생했습니다.')
			}
		}

		const closeDialog = async () => {
			notice.value.title = ''
			notice.value.content = ''
			notice.value.files = []
			emit('update:is-open-my-page-notice-dialog', false)
		}

		const isSmAndDown = computed(() => {
			return $vuetify.breakpoint.smAndDown
		})

		watch(
			() => props.selectedNotice,
			async currentValue => {
				if (currentValue) {
					getNoticeDetail(currentValue.id)
				}
			},
		)

		return {
			notice,
			isSmAndDown,

			closeDialog,
		}
	},
}
</script>
<style lang="scss" scoped>
::v-deep {
	table {
		border: 1px solid rgba(0, 0, 0, 0.1);
		margin: 12px 0 14px;
		color: #222;
		width: auto;
		border-collapse: collapse;
		box-sizing: border-box;

		th,
		td {
			border: 1px solid rgba(0, 0, 0, 0.1);
			padding: 5px 14px 5px 12px;
			height: 32px;
		}

		th {
			background-color: #555;
			font-weight: 300;
			color: #fff;
			padding-top: 6px;
			font-weight: bold;

			p {
				margin: 0;
				color: #fff;
			}
		}

		td {
			margin: 0;
			padding: 0 2px;
		}
	}
}

.notification,
.notice {
	.v-list-item {
		border-bottom: 1px solid #e0e0e0;

		&:last-child {
			border-bottom: none;
		}
	}
}
</style>
